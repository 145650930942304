export default {
    mixRecharge: ["이메일 입려하십시오!"],
    language: "언어",
    common: ["온라인 고객 지원 센터", "취소","승인","취소"],
    upload: ["업로드","업로드 파일 규격이 잘못됩니다!", "업로드 성공", "업로드 실패"],
    vanPull: ["추가 데이터 없습니다!", "데이터 없습니다!"],
    login: {
        text: ["언어","SPAC","로그인"],
        label: ["로그인 중", "바로 로그인"],
        placeholder: ["이메일을 입력하십시오!!", "비밀번호를 업력하십시오!!"],
        default: ["이미 계정이 있으신가요?", "새로운 계정 바로 만들기","비밀번호를 잊으셨나요?"," 비밀번호를 잊으셨나요?"],
        codes:["계정이 존재하지 않습니다!","비밀번호가 잘못됩니다!","계정이 동결됩니다!","실패"],
        text2: ['메일', '확인', '비밀번호', '초대', '전화'],
        text1: ['로그인', '웹에 오신 것을 환영합니다', '이메일 또는 전화번호', '비밀번호', '로그인']
    },
    register: {
        text: [
            "크립토",
            "인증번호 전송 중",
            "인증 받기",
            "등록 중",
            "가입",
        ],
        placeholder: [
            "이메일 입력",
            "인증번호 입력",
            "비밀번호 입력",
            "비밀번호 재입력",
            "추천인 코드 입력",
            "비밀번호는 일치되지 않습니다!",
            "인증번호 입력",
            "이메일 잘못되었습니다!"
        ],
        label: ["계정이 이미 있습니까?{a} {line}", "로그인 화면으로 돌아가기"],
        codes:["계정이 이미 존재되었습니다!","인증번호 엽력되지 않습니다!","인증번호가 잘못됩니다!"," 비밀번호는 일치되지 않습니다! ","추천인 코드는 존재되지 않습니다!","실패"]
    },
    resetpwd:["비밀번호 재설정","이메일 업력","인증번호 입력","인증 받기","비밀번호 입력","비밀번호 설정"],
    footer: ["홈페이지", "거래소", "주문서", "나의"],

    home: {
        label:["환영합니다"],
        broadcast: "{member}회원님 {grade}레벨이 되어 축하드립니다!",
        menu: ["거래내역", "지도시간", "초대하기",
            "고객센터"],
        noticeTitle: "최신 공지사항",
        msg: "미개봉 퀘스트",
        video: "안내 비디오가 일시적으로 없습니다!",
    },
    hall:{
        default:[
            "거래소","잔액","구매하기","판매하기","판매 확인",
            "주문서 리스트","판매 주문 리스트","VIP에게 판매","주문 일시 중지","구매","판매"
        ],
        list:[
            '수량','단가', '거래 미완료','USDT 구매','USDT 금액 입력','수취금액','최소 금액','USDT로 구매','수수료 금액',"최대 금액"
        ],
        kbip:["취소","정액 초과되었습니다!","정한 범위내에 거래할 수 있습니다"]
    },
    msglist:[
        "정보 리스트"
    ],
    sell:{
        placeholder:["판매 단가를 입력하십시오!!","판매 수량을 입력하십시오!!"],
        label:["잔액(USDT)","성함","은행계좌","현재 가격(USDT)","수익이 가장 높은 금액", "판매액","판매수량","수익이 가장 높은 판매가","전체","판매 확인"]
    },
    buy:{
        placeholder:["구매액 입력하십시오!","구매수량을 입력하십시오!","최소 구매액","최고 구매액"],
        label:["잔(USDT)","성함","은행계좌","현재 가격(USDT)", "수익이 가장 높은 구매 가격","구매 가격","구매수량","수익이 가장 높은 가격","전체","구매 확인","구매제한액"]
    },
    user: {
        default: [
            "개인 센터",
            "로그인 계정",
            "추천인 코드",
            "로그아웃",
            "잔액",
            "금화",
            "지갑",
        ],
        menu: [
            "나의 자산",
            "그룹 목록",
            "계정정보",
            "거래내역",
            "플랫폼 소개",
            "로그아웃",
        ],
    },
    bindAccount: {
        default: ["은행계좌 정보","제출"],
        fields: ["성함", "전화 번호", "은행계좌","이름","구분","USDT주소"],
        placeholder: ["성함 입력","전화 번호 입력","은행계좌 입력","은행계좌 추가",
            "USDT종료 선택","USDT주소 입력"],
        fields2: ["계정 이름", "전화번호", "계정", "은행 이름", "유형","주소"],
        placeholder2: ["계정 이름을 입력하세요", "전화번호를 입력하세요", "계정을 입력하세요",
            "은행 이름을 압력",
            "USDT 유형을 선택하세요", "USDT 주소를 입력하세요"],
    },
    wallet: {
        default: [
            "내 자산",
            "입금",
            "출금",
            "입금 기록",
            "출금 기록",
            "입금액",
            "판매액",
            "수입",
            "계정 잔액",
            "잔액",
            "입금액(USDT)",
            "판매 금액",
            "총 수입",
            "상인 업그레이드",
            "VIP계정 업그레이드, 고객 지원 서비스에 문의",
            "VIP 가입 확인",
            "VIP 확인",
            "결제가 필요합니다","지불증명서","상인 보증금","바로 확인하십시오!","정보","결제해야 합니다!","잔액이 없으니 입금하시겠습니까? "
        ],
        label: [
            "출금 방법",
            "출금액",
            "펀드 비밀번호",
            "제출",
            "전화 번호",
            "이메일",
            "IFSC",
            "확인",
        ],
        placeholder: [
            "출금 방법 선택",
            "출금액 입력",
            "펀드 비밀번호 입력",
            "출금 방법 선택",
            "수취인의 전화번호 입력",
            "수츼인의 이메일 입력",
            "수취인의 IFSC 입력",
        ],
        msg: [
            "펀드 비밀번호가 설정되지 않았습니다. 바로 설정하십시오!",
            "PIX 계정을 연결하지 않았습니다. 바로 설정하십시오!",
            "출금을 확인하시겠습니까?",
        ],
    },
    recharge: {
        default: [
            "지갑에 입금",
            "지갑에 바로 입금",
            "입금 방법",
            "입금",
            "확인",
        ],
        label: [
            "유형",
            "주소",
            "입금액",
            "금액 입력",
            "지불증명서 업로드",
            "성공적으로 복사",
            "거래소에 먼저 입금",
        ],
        placeholder: [
            "유형",
            "주소",
        ],
        info: [
            "입금액",
            "주문 번호",
            "수취인의 은행",
            "수취인의 계정",
            "수취인",
            "복사",
        ]
    },
    task: {
        tabs: ["전체","처리 중", "정지 완료", "완료", "VIP 결제 대기 중", "확인 대기 중"],
        default: ["주문 목록",],
        msgstr: ["검토를 위해 제출", "제출이 완료되었습니다. 검토를 기다리십시오!", "제출이 실패되었습니다. 다시 제출하십시오!"],
    },
    userInfo: {
        default: [
            "개인 정보",
            "프로필",
            "계정",
            "이메일",
            "신용 점수",
            "비밀번호 변경",
            "계정 비밀번호",
            "바로 설정",
            "비밀번호 변경",
            "프로필 사진 변경",
            "펀드 비밀번호 변경",
            "제출",
            "메모리 지우기",
            "정보 확인",
            "닉네임",
            "추천인 코드","추천 코드"
        ],
        label: [
            "현재 비밀번호",
            "새로운 비밀번호",
            "비밀번호 확인",
            "기존 펀드 비밀번호",
            "새로운 펀드 비밀번호",
            "비밀번호 확인",
            "저장"
        ],
        placeholder: [
            "현재 비밀번호 입력",
            "새로운 비밀번호 입력",
            "로그인 비밀번호 입력",
            "현재 펀드 비밀번호 입력",
            "새로운 펀드 비밀번호 입력",
            "새 펀드 비밀번호 재입력",
        ],
    },
    fundRecord: {
        default: ["출금 기록", "입금 기록", "거래내역", "입금", "받기", "지출"],
        tabs: ["수입", "지출", "입금"],
    },
    dialog: [
        "리마인드",
        "확인",
        "제출 중",
        "복사 완료",
        "IOS 시스템 버전은 지원되지 않습니다!",
        "등록 중",
        "데이터 로드 중",
        "데이터 로드 중",
    ],
    serviceCenter: [
        "고객지원센터",
        "안녕하세요, 고객지원센터입니다~",
        "고객님을 섬기게 되어 기쁩니다",
        "셀프 서비스",
        "온라인 고객 지원",
        "입금한 고객 지원",
        "라인 고객 지원",
    ],
    userTaskRecord: [
        "내 주문",
        "판매 주문",
        "구매 주문",
        "현재 상태",
        "수수료 받기",
        "완료",
    ],
    withdrawlist: [
        "출금 이력",
    ],
    teamReport: {
        default: [
            "그룹 목록",
        ],
    },
    common2:['신입회원 안내',"고객지원센터 문의","서비스 약관, 정보확인", "고객지원 1", "고객지원2"],
    common3:['성공',"플랫폼 소개"],
    invite:['공유 및 프로모션','나의 추천 코드',"추천코드를 복사해서 친구들과 공유해보세요","복사",'Không thể mời thành viên vào lúc này',"초대할 수 없음"],
    common4:['실명확인',"제출 성공","주의 작업 항목","작업 항목 내역","결제 금액","세금액",
        "재정 증명서","은행계정 추가를 먼저 완료하십시오!","실명 확인을 먼저 완료하십시오!"],
    common5:["판매","분","이전","QR코드 저장","열기","계정 동결, 일시적으로 거래할 수 없습니다!","앱 다운로드"],
    common6:["주문 내역","주문 번호","금액","시간, 상태"],
    Hall2:["최소 판매 금액","","일일 판매 제한 금액을 초과했습니다!"],
    register2:["이메일 등록","전화번호 등록","전화번호 입력"],
    withdraw2:["출금","판매자 계정","계정잔액","결제금액","바로 결제","미완성된 작업이 있으니 일시적으로 출금할 수 없습니다!","일일 출금횟수 초과","설명"],
    mytask2:["VIP 계정","가격","금액","계정 잔액","결제 금액","남은 시간","주문 정보","수량은 0일 수 없습니다!","잔액 부족"," 가격은 0일 수 없습니다!","범위가 잘못되었습니다!"],
    taskOrder2:["완료되지 않은 주문이 있으니 취소할 수 없습니다!","잔액 부족","확인","실패","주문을 결제하여 출금할 수 있습니다!" ],
    busAuth2:['판매자 업그레이드', '동의합니다', '업그레이드 확인', '상인 업그레이드',
        "은행 계좌 정보 수정을 원하시면 고객센터로 문의 바랍니다.","현재 계정은 상인 계정으로 업그레이드해야 합니다"],
    recharge2:['금액은 0일 수 없습니다!','스크린샷을 업로드해야 합니다!'],
    buy3:["카운트다운","시","유형",'복지 주문서','공동 주문서','인력제한수','인력제한수가 입력되지 않습니다!','사용자 ID 지정','입력하십시오'],
    hall3:["일정","시","분","초","공동주문서","계정",'시간이 되었습니다!'],
    sell3:['판매가는 최고 가격보다 적어야 합니다!'],
    busAuth3:["회원","상인","왕관상인"],
    recharge3:["계산"],
    home3: ["자본 기록", "퍼즐", "블라인드 박스", "재정 관리", "기다리는 중"],
    home4: ["빠른 거래", "USDT 구매 클릭", "P2P 거래", "USDT 구매 및 판매", "온라인 사용자", "24시간 거래량 (USDT)"],
    common7: ["문제가 해결되지 않았다면 고객 서비스에 직접 문의하십시오", "지원 부서에 문의하기", "상담하기 클릭", "설정", "모두", "친구 초대", "그룹 규모", "월별 신규 추가", "등록 시간", "하위 수량"],
    hall4: ['귀하의 신분', '회원', "계정 정보", "거래 성공", "성공적으로 완료하셨습니다", "금액","구매하려면","판매"],
    task3:["모두","진행 중","만료됨","완료됨"],
    my:["계정에서 로그아웃 하시겠습니까?"],
    bindAccount2:["은행 계좌 정보를 정확하게 기재.",
        "확인","지원 부서"],
    recharge4:["안전한 거래를 위해 이체 후 거래내역을 스크린샷하여 업로드하십시오"],
    resetpwd2:["이메일","전화번호","참고: 비밀번호는 6~16자로 구성되며 대소문자를 구분하지 않습니다"],

    home2:["특별 관심","자금 시장"],
    login1:["다음","지금 로그인","계정이 없는 것 같습니다. 새 계정을 만들어 보세요:","동의하고 계속하기"],
    login2:["더 많은 로그인 방법","계속하기","Facebook","Google","Apple"],
    service2:["거래","기타"],
    buy2:["주문 유형","구매 성공","USDT가 성공적으로 구매되었습니다"],
    sell4:["판매 확인","확인","판매 성공","성공적으로 판매하셨습니다","구매자가 지불하기를 기다리세요","완료"],

    hall5:["회원"],
    busauth:["정보 확인","실명을 입력하세요","신분증 번호를 입력하세요","사진 업로드","신원 정보 업로드","판매자 예금 내역"],
    recharge5:["사진을 업로드하세요","충전 성공", "USDT가 계정으로 성공적으로 충전되었습니다","확인"],
    note:["참고","팁","전화번호","전화번호를 입력하세요","메시지 남기기","여기에 메시지를 남겨주세요", "사진 업로드","사진을 업로드하세요","제출 성공","가능한 빨리 연락드리겠습니다","닫기"],
    notelist:[ "참고 목록" ],
    search:["검색","데이터를 찾을 수 없습니다","키워드를 입력하여 찾으세요"],
    searchs:{ orders:"주문",recharge:"충전",withdraw:"출금" },
    team2:["팀 상세 정보","친구 초대"],
    task4:["주문 목록"],
    lang3:["영어","한국어","중국어"],
    login3:["아이디나 비밀번호가 잘못되었습니다.다시 입력하십시오."],
    shiming:['세무 검토'],
};
